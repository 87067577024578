<template>
  <section class="page-wrap">
    <!-- 表单 start -->
    <div class="search">
      <div class="title">
        <div class="search-item">
          <el-input
            v-model="formData.order_no"
            placeholder="输入订单编号"
            clearable
          ></el-input>
        </div>
        <div class="search-item">
          <el-input
            v-model="formData.as_no"
            placeholder="输入售后单号"
            clearable
          ></el-input>
        </div>
        <div class="search-item">
          <el-date-picker
            v-model="formData.pay_start"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择支付起始"
          >
          </el-date-picker>
        </div>
        <div class="search-item">
          <el-date-picker
            v-model="formData.pay_end"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择支付截止"
          >
          </el-date-picker>
        </div>
        <div class="search-item multiple-select-long">
          <el-select
            @change="cityChange"
            filterable
            multiple
            v-model="formData.logistics_id"
            clearable
            collapse-tags
            placeholder="选择城市仓"
          >
            <li
              class="el-select-dropdown__item"
              :class="{ selected: serviceSelectedAll }"
              @click="onServiceSelectedAll"
            >
              <span>全选</span>
            </li>
            <el-option
              v-for="item of userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="search-item multiple-select">
          <el-select
            v-model="formData.delivery_id"
            multiple
            clearable
            placeholder="选择自提点"
          >
            <el-option
              v-for="item of selflist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="search-item multiple-select">
          <el-select
            v-model="formData.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id + ''"
            ></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-input
            v-model="formData.mobile"
            placeholder="输入电话"
            clearable
          ></el-input>
        </div>
        <div class="search-item">
          <el-input
            v-model="formData.supplier_order_no"
            placeholder="输入供货单号"
            clearable
          ></el-input>
        </div>
        <div class="search-item">
          <el-input
            v-model="formData.trade_no"
            placeholder="输入微信支付单号"
            clearable
          ></el-input>
        </div>
        <div class="search-item">
          <el-input
            v-model="formData.refund_no"
            placeholder="输入退款单号"
            clearable
          ></el-input>
        </div>
        <div class="search-item">
          <el-date-picker
            v-model="formData.refund_start"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择退款起始"
          >
          </el-date-picker>
        </div>
        <div class="search-item">
          <el-date-picker
            v-model="formData.refund_end"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择退款截止"
          >
          </el-date-picker>
        </div>
        <div class="search-item">
          <el-select
            v-model="formData.refund_status"
            clearable
            placeholder="选择退款状态"
          >
            <el-option
              v-for="item of statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="bjsearch"
            @click="onHandleRefresh"
          ></el-button>
        </div>
        <div class="search-item">
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
        <div class="search-item">
          <el-button
            @click="onHandleExport"
            type="warning"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单 end -->
    <!-- table start -->
    <div class="table-wrap">
      <el-table
        height="100%"
        v-loading="loading"
        border
        :data="dataList"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        tooltip-effect="dark"
      >
        <el-table-column
          label="集配中心"
          prop="logistic_business_name"
          align="center"
        >
        </el-table-column>
        <el-table-column label="订单编号" prop="order_no" align="center">
        </el-table-column>
        <el-table-column label="售后单号" prop="as_no" align="center">
        </el-table-column>
        <el-table-column label="微信交易单号" prop="trade_no" align="center">
        </el-table-column>
        <el-table-column
          label="供货单号"
          prop="supplier_order_no"
          align="center"
        >
        </el-table-column>
        <el-table-column label="订单创建时间" prop="order_time" align="center">
          <template slot-scope="scope">
            {{ $empty.time(scope.row.order_time) }}
          </template>
        </el-table-column>
        <el-table-column label="客户名称" prop="user_name" align="center">
        </el-table-column>
        <el-table-column label="客户电话" prop="user_mobile" align="center">
        </el-table-column>
        <el-table-column label="支付时间" prop="pay_at" align="center">
        </el-table-column>
        <el-table-column
          label="退款状态"
          prop="order_refund_state"
          align="center"
        >
        </el-table-column>
        <el-table-column label="订单金额" prop="order_amount" align="center">
        </el-table-column>
        <el-table-column
          label="城市仓"
          prop="store_logistic_name"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="自提点"
          prop="store_delivery_name"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="退款金额"
          prop="aftersale_amount"
          align="center"
        >
        </el-table-column>
        <el-table-column label="退款单号" prop="refund_no" align="center">
        </el-table-column>
        <el-table-column label="退款时间" prop="refund_time" align="center">
        </el-table-column>
        <el-table-column label="缺货商品" prop="product_name" align="center">
        </el-table-column>
        <el-table-column label="缺货件数" prop="product_num" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- table end -->
  </section>
</template>

<script>
import { getRefundExport } from "@/api/export";
import { downLoadFlowBlob } from "@/utils/tools/base";
export default {
  name: "MoneyToReview",
  data() {
    return {
      formData: {
        order_no: "",
        as_no: "",
        logistic_business_id: "",
        pay_start: "",
        pay_end: "",
        logistics_id: [],
        delivery_id: [],
        mobile: "",
        trade_no: "",
        supplier_order_no: "",
        refund_no: "",
        refund_start: "",
        refund_end: "",
        refund_status: "",
        page: 1,
        pageSize: 10,
      },
      userList: [],
      selflist: [],
      statusList: [
        {
          id: "1",
          name: "未退款",
        },
        {
          id: "2",
          name: "已退款",
        },
        {
          id: "3",
          name: "退款失败",
        },
      ],
      dataList: [],
      options: [],
      currentPage: 1,
      total: 0,
      loading: false,
    };
  },
  computed: {
    // 全选的勾选状态 true、false
    serviceSelectedAll() {
      return this.formData.logistics_id.length === this.userList.length;
    },
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.formData = url;
      this.currentPage = Number(this.formData.page);
      this.formData.pageSize = Number(this.formData.pageSize);
      this.formData.logistics_id = this.formData.logistics_id
        ? this.formData.logistics_id
        : [];
      console.log(this.formData);
    }
    if (this.formData.logistics_id) {
      this.getListData(this.formData.logistics_id);
    }
    this.onHandleData();
    this.citylist();
    this.getBusinessList();
  },
  methods: {
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    getListData(val) {
      try {
        this.$api.general
          .deliveryList({
            logistics_id: val, //必须填写
            page: 1,
            pageSize: 1000,
          })
          .then((res) => {
            console.log(res, "获取数据");
            this.selflist = res.data.data;
          });
      } catch {
        console.log("ajax err", err);
      }
    },
    citylist() {
      try {
        this.$api.general
          .logisticsList({
            name: "",
            page: 1,
            pageSize: 1000,
          })
          .then((res) => {
            console.log(res, "获取数据");
            this.userList = res.data.data;
          });
      } catch {
        console.log("ajax err", err);
      }
    },
    onServiceSelectedAll() {
      if (this.serviceSelectedAll) {
        // 取消全选
        this.formData.logistics_id = [];
      } else {
        this.formData.logistics_id = this.userList.map((item) => item.id);
      }
    },
    cityChange(val) {
      this.getListData(val);
    },
    /**
     * 刷新数据
     */
    onHandleRefresh() {
      try {
        this.formData = {
          order_no: "",
          as_no: "",
          logistic_business_id: "",
          pay_start: "",
          pay_end: "",
          logistics_id: [],
          delivery_id: [],
          mobile: "",
          refund_no: "",
          refund_start: "",
          refund_end: "",
          refund_status: "",
          page: 1,
          pageSize: 10,
        };
        this.tool.getResult(this.formData, window.location.href);
        this.onHandleData();
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 获取数据
     */
    onHandleData() {
      try {
        this.loading = true;
        this.$api.general.salerefundlist(this.formData).then((res) => {
          console.log(res, "获取数据");
          this.dataList = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 查询
     */
    onHandleSearch() {
      try {
        this.formData.page = 1;
        this.currentPage = 1;
        this.tool.getResult(this.formData, window.location.href);
        this.onHandleData();
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 导出
     */
    async onHandleExport() {
      this.$message.success({
        message: "正在导出，请耐心等待",
        duration: 99999999,
      });
      try {
        let params = this.tool.DeepCopy({}, this.formData);
        delete params.page;
        delete params.pageSize;
        console.log(params);
        const res = await getRefundExport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.closeAll();
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.closeAll();
        this.$message.error("导出失败了");
        console.log("ajax err", err);
      }
    },
    /**
     * 更新了每页数据
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.tool.getResult(this.formData, window.location.href);
      this.onHandleData();
    },
    /**
     * 更新了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.currentPage = val;
      this.tool.getResult(this.formData, window.location.href);
      this.onHandleData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;

  /deep/ .el-table .el-table__body .el-table__cell .cell:empty::after {
    content: "—";
  }

  .search {
    box-sizing: border-box;
    background: #fff;

    .title {
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  .bjsearch {
    background: #333333;
    border-color: #333333;
  }
  .table-wrap {
    flex-grow: 1;
    overflow-y: hidden;
    // padding: 15px;
    background: #fff;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
